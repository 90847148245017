import Navbar from "../Navbar";
import Footer from "../Footer";
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div className='bg-[#f0f8ff]'>
      <Navbar />
      <div className='mx-auto'>
      <Outlet />
      </div>
      <Footer/>
    </div>
  )
}
export default Layout;