import "./App.css";
import { BrowserRouter } from "react-router-dom";
import RoutesHOC from "./routes/RoutesHOC";

function App() {
  return (
    <BrowserRouter>
      <RoutesHOC />
    </BrowserRouter>
  );
}
export default App;
