import { Suspense, lazy } from 'react';

const Login = lazy(() => import('../../pages/Login'));
const Signup = lazy(() => import('../../pages/Signup'));


const AuthPaths = [
  {
    path: '/login',
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Login />
      </Suspense>
    )
  },
  {
    path: '/signup',
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Signup />
      </Suspense>
    )
  }
];
export default AuthPaths;
