import { Suspense, lazy } from 'react';
const Profile = lazy(() => import('../../pages/Profile'));

const PrivatePaths = [
  {
    path: '/profile',
    Content: () => (
      <Suspense fallback={<div>...Fallback</div>}>
        <Profile />
      </Suspense>
    )
  }
];
export default PrivatePaths;
