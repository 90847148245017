import React, { useState } from "react";
import "./Footer.css";
import { SiFacebook } from "react-icons/si";
import { FaSquareInstagram, FaLinkedin } from "react-icons/fa6";
import { FaYoutubeSquare } from "react-icons/fa";
import { FcContacts } from "react-icons/fc";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Subscribed with:", email);
    setEmail("");
  };
  return (
    <div className="footerWrapper">
      <div className="footerContainer">
        <div className="footerpart1">
          <div>
            <h2 className="footerLogo">
              Deals<span style={{ color: "#1C61E7" }}>Bajaar</span>
            </h2>
            <p className="footerDesc">
              DealsBajaar – your ultimate online destination for mobile phones,
              laptops, tablets, PCs, appliances, and more. Save time, save
              money, and stay ahead of the tech curve with DealsBajaar
            </p>
          </div>
          <div className="footerIconsSocial">
            <div className="subscribeUs">Subscribe us:</div>
            <div style={{ display: "flex", marginTop: "10px", gap: "5px" }}>
              <a
                href="https://www.facebook.com/people/Dealsbajaar/61559077534326/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiFacebook size={30} color="#365493" />
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSquareInstagram
                  size={30}
                  color="#F14FFF"
                  style={{ borderRadius: "50%" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/dealsbajaar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin size={30} style={{ borderRadius: "50%" }} />
              </a>
              <a
                href="https://www.youtube.com/channel/UChSVTMwp5sANNf6-kJpy1oQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutubeSquare
                  size={30}
                  color="#FF0000"
                  style={{ borderRadius: "50%" }}
                />
              </a>
              <a
                href="https://www.dealsbajaar.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FcContacts
                  size={30}
                  color="#0072C6"
                  style={{ borderRadius: "50%" }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="footerpart2">
          <span className="footerPartTitle">Categories</span>
          <ul className="footerpart2desc">
            {[
              "SmartPhones",
              "Laptop",
              "TVs",
              "Refrigerator",
              "SmartWatch",
              "AirPurifier",
              "Earbud",
            ].map((item) => (
              <li key={item}>
                <a href={`/${item.toLowerCase()}`}>{item}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footerpart3">
          <span className="footerPartTitle">Brands</span>
          <ul className="footerpart2desc">
            {["Samsung", "Redmi", "Poco", "Realme", "Apple", "Oppo"].map(
              (brand) => (
                <li key={brand}>
                  <a href={`/mobiles?brand=${brand.toLowerCase()}`}>{brand}</a>
                </li>
              )
            )}
          </ul>
        </div>
        <div className="footerpart4">
          <span className="footerPartTitle">Useful Links</span>
          <ul className="footerpart2desc">
            {["Blogs", "About", "Contact", "News", "Privacy"].map((item) => (
              <li key={item}>
                <a href={`/${item.toLowerCase()}`}>{item}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footerpart5">
          <span className="footerPartTitle">Subscribe to our Newsletter</span>
          <span className="footerpart6desc">15% discount on your purchase</span>
          <form onSubmit={handleSubmit} className="newsletterForm">
            <input
              type="email"
              placeholder="@  Enter your email"
              value={email}
              onChange={handleInputChange}
              className="newsletterInput"
            />
            <button type="submit" className="subscribeButton">
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Footer;
