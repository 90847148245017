import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { useNavigate, Link } from "react-router-dom";
import { IoSearchCircleSharp } from "react-icons/io5";
import { IoListCircle } from "react-icons/io5";
import { FaGgCircle } from "react-icons/fa6";
import { MdOutlineLaptopMac } from "react-icons/md";
import { PiTelevisionBold } from "react-icons/pi";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { SlScreenSmartphone } from "react-icons/sl";
import { CgSmartHomeRefrigerator } from "react-icons/cg";
import { TbAirConditioning } from "react-icons/tb";
import { ImBook } from "react-icons/im";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoIosTabletPortrait } from "react-icons/io";
import { TbBrand4Chan } from "react-icons/tb";
import { BsEarbuds } from "react-icons/bs";
import { TbVacuumCleaner } from "react-icons/tb";

const Navbar = () => {
  const navigate = useNavigate();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showSubSubMenu, setShowSubSubMenu] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const token = localStorage.getItem("token");
  const fullName = localStorage.getItem("fullName") || "";
  const [allSearchResults, setAllSearchResults] = useState([]);
  const [displayedResults, setDisplayedResults] = useState([]);
  const [resultIndex, setResultIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showPopularBrandsSubMenu, setShowPopularBrandsSubMenu] =
    useState(false);
  const [showPriceSubMenu, setShowPriceSubMenu] = useState(false);
  const [showFeaturesSubMenu, setShowFeaturesSubMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isBrandHovered, setIsBrandHovered] = useState(false);
  const [isPriceHovered, setIsPriceHovered] = useState(false);
  const [showSizeSubMenu, setShowSizeSubMenu] = useState(false);
  const [isSizeHovered, setIsSizeHovered] = useState(false);
  const [showCompareSubMenu, setShowCompareSubMenu] = useState(false);

  const handleCompareSubMenuToggle = (value) => {
    setShowCompareSubMenu(value);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category.path);
    setIsDropdownVisible(true);
  };
  const userInitial = fullName
    ? fullName
        .split(" ")
        .map((name) => name[0])
        .join("")
    : "";

  const handleSubMenuToggle = () => {
    setShowSubMenu(!showSubMenu);
  };
  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
  const handleClick = (url) => {
    navigate(url);
    setIsDropdownVisible(false);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("fullName");
    navigate("/");
  };
  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (!query.trim()) {
      setAllSearchResults([]);
      setDisplayedResults([]);
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/products/search?q=${query}`
        // `http://localhost:8000/api/products/search?q=${query}`
      );
      if (!response.ok) throw new Error("Search request failed");
      const data = await response.json();
      setAllSearchResults(data);
      setDisplayedResults(data.slice(0, 10));
      setResultIndex(20);
    } catch (error) {
      setSearchError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreResults = () => {
    const nextResults = allSearchResults.slice(resultIndex, resultIndex + 10);
    setDisplayedResults(displayedResults.concat(nextResults));
    setResultIndex(resultIndex + 10);
  };

  useEffect(() => {
    if (searchQuery.trim()) {
      handleSearch(searchQuery.trim());
    }
  }, [searchQuery]);
  const togglePopularBrandsSubMenu = () =>
    setShowPopularBrandsSubMenu(!showPopularBrandsSubMenu);
  const togglePriceSubMenu = () => setShowPriceSubMenu(!showPriceSubMenu);
  const toggleFeaturesSubMenu = () =>
    setShowFeaturesSubMenu(!showFeaturesSubMenu);
  const toggleSizeSubMenu = () => {
    setShowSizeSubMenu(!showSizeSubMenu);
  };

  return (
    <div className="navbarwrapper">
      <div className="navbarContainer">
        <div className="navbarTopBar">
          <div className="navbarLogo">
            <Link to="/">
              Deals<span style={{ color: "#1C61E7" }}>Bajaar</span>
            </Link>
          </div>
          <div className="navbarTopSearchbar">
            <IoSearchCircleSharp
              className="search-icon"
              color="#1C61E7"
              size={30}
              onClick={toggleDropdown}
            />
            <input
              type="text"
              placeholder="Search Product Here"
              className="search-box"
              onClick={toggleDropdown}
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {isDropdownVisible && (
              <div className="dropdownss">
                {isLoading && <div className="dropdownss-item">Loading...</div>}
                {searchError && (
                  <div className="dropdownss-item">Error: {searchError}</div>
                )}
                {!isLoading && !searchError && displayedResults.length > 0 ? (
                  <>
                    {displayedResults.map((result) => (
                      <div
                        key={result.id}
                        className="dropdownss-item"
                        onClick={() => handleClick(result._id)}
                      >
                        <div className="flex items-center justify-start gap-5">
                          <img src={result.img_url[0]} alt="" />
                          <div className="text-[#000000] font-light text-xs">
                            {result.title}
                          </div>
                          <div className="text-[#000000] font-light text-xs">
                            ₹ {result.price}
                          </div>
                        </div>{" "}
                      </div>
                    ))}
                    {resultIndex < allSearchResults.length && (
                      <div
                        className="dropdownss-item load-more text-center"
                        onClick={loadMoreResults}
                      >
                        Load More
                      </div>
                    )}
                  </>
                ) : (
                  <div className="dropdownss-item">No results found</div>
                )}
              </div>
            )}
          </div>
          <div className="navbarTopLeftContent">
            {!token ? (
              <>
                <div className="navbarContact">
                  <Link to="/login">
                    <button className="bg-transparent hover:bg-[#1C61E7] text-[#1C61E7] font-semibold hover:text-white py-1 px-3 border border-[#1C61E7] hover:border-transparent rounded transition-all">
                      <div className="flex items-center gap-1">
                        <span>Login</span>
                        <IoIosArrowRoundForward size={25} />
                      </div>
                    </button>
                  </Link>
                </div>
                <div className="navbarEmail">
                  <Link to="/signup">
                    <button className="bg-black hover:bg-transparent text-white hover:text-black font-bold py-1 px-3 border border-black rounded transition-all">
                      <div className="flex items-center gap-1">
                        <span>Signup</span>
                        <IoIosArrowRoundForward size={25} />
                      </div>
                    </button>
                  </Link>
                </div>
              </>
            ) : (
              <div className="flex items-center gap-2">
                <div className="flex justify-center items-center w-10 h-10 bg-[#1C61E7] text-white font-bold rounded-full uppercase">
                  {userInitial}
                </div>
                <button
                  onClick={handleLogout}
                  className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-3 py-2 text-center"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="navbarbottomBarWrapping">
          <div className="navbarBottomBar">
            <div className="navbarLeftCont">
              <div className="navbarIcon" onClick={handleSubMenuToggle}>
                <IoListCircle size={30} color="#1C61E7" />
                <p>All Categories</p>
                {showSubMenu && (
                  <div className="submenu">
                    <div className="submenuItem withBorder">
                      <ImBook size={20} color="#111111" />
                      <p>Categories</p>
                    </div>
                    <div
                      className="submenuItem"
                      onMouseEnter={() => setShowSubSubMenu(true)}
                      onMouseLeave={() => setShowSubSubMenu(false)}
                    >
                      <SlScreenSmartphone size={20} color="#111111" />
                      <Link to="/smartphones">Smartphones</Link>
                      {showSubSubMenu && (
                        <div className="subsubmenu">
                          <div className="subsubmenuItem">
                            <Link to="/smartphones">
                              All Mobiles & Accessories
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/smartphones/?available=bestseller">
                              Popular Mobiles
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/smartphones/?available=upcoming">
                              Upcoming Mobiles
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/smartphones/?available=new">
                              New Mobiles
                            </Link>
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isBrandHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Popular Brands{" "}
                              <span className="right-arrow popular-brands">
                                {">"}
                              </span>
                            </span>
                            {showPopularBrandsSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?brand=apple">
                                    Apple Mobiles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?brand=samsung">
                                    Samsung Mobiles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?brand=realme">
                                    Realme Mobiles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?brand=mi">
                                    Redmi Mobiles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?brand=vivo">
                                    Vivo Mobiles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?brand=motorola">
                                    Motorola Mobiles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?brand=oppo">
                                    Oppo Mobiles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?brand=oneplus">
                                    OnePlus Mobiles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?brand=poco">
                                    Poco Mobiles
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isPriceHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Prices{" "}
                              <span className="right-arrow prices">{">"}</span>
                            </span>
                            {showPriceSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?minPrice=5000&maxPrice=10000">
                                    Best Under <span>&#8377;</span>10000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?minPrice=10000&maxPrice=20000">
                                    Best Under <span>&#8377;</span>20000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?minPrice=20000&maxPrice=30000">
                                    Best Under <span>&#8377;</span>30000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?minPrice=30000&maxPrice=40000">
                                    Best Under <span>&#8377;</span>40000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?minPrice=40000&maxPrice=50000">
                                    Best Under <span>&#8377;</span>50000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?minPrice=50000&maxPrice=60000">
                                    Best Under <span>&#8377;</span>60000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?minPrice=60000&maxPrice=70000">
                                    Best Under <span>&#8377;</span>70000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?minPrice=70000&maxPrice=80000">
                                    Best Under <span>&#8377;</span>80000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?minPrice=80000&maxPrice=90000">
                                    Best Under <span>&#8377;</span>90000
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(true);
                            }}
                            onMouseLeave={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Feature{" "}
                              <span className="right-arrow features">
                                {">"}
                              </span>
                            </span>
                            {showFeaturesSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?connectivity=5G">
                                    5G Mobiles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?processorBrand=snapdragon">
                                    Snapdragon Processors
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?processorBrand=mediatek">
                                    MediaTek Processors
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?ram=8">
                                    8GB RAM Mobiles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?rom=256">
                                    256GB ROM Mobiles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones?minPrice=10000&maxPrice=20000&connectivity=5G">
                                    5G Under <span>&#8377;</span>20000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/mobsmartphonesiles?battery=6000-mah">
                                    6000 mAh Battery
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smartphones">Gaming Mobiles</Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/powerbank">Power Banks</Link>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="submenuItem"
                      onMouseEnter={() => setShowSubSubMenu(true)}
                      onMouseLeave={() => setShowSubSubMenu(false)}
                    >
                      <IoIosTabletPortrait size={20} color="#111111" />
                      <Link to="/smart-tablets">Tablets</Link>
                      {showSubSubMenu && (
                        <div className="subsubmenu">
                          <div className="subsubmenuItem">
                            <Link to="/smart-tablets">All Tablets</Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/smart-tablets/?available=bestseller">
                              Popular Tablets
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/smart-tablets/?available=upcoming">
                              Upcoming Tablets
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/smart-tablets/?available=new">
                              New Tablets
                            </Link>
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isBrandHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Popular Brands{" "}
                              <span className="right-arrow popular-brands">
                                {">"}
                              </span>
                            </span>
                            {showPopularBrandsSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?brand=samsung">
                                    Samsung Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?brand=apple">
                                    Apple Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?brand=nokia">
                                    Nokia Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?brand=lenovo">
                                    Lenovo Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?brand=mi">
                                    Redmi Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?brand=honor">
                                    Honor Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?brand=realme">
                                    Realme Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?brand=iball">
                                    iBall Tablets
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isPriceHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Prices{" "}
                              <span className="right-arrow prices">{">"}</span>
                            </span>
                            {showPriceSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?minPrice=50000&maxPrice=10000">
                                    Best Under <span>&#8377;</span>10000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?minPrice=10000&maxPrice=20000">
                                    Best Under <span>&#8377;</span>20000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?minPrice=20000&maxPrice=30000">
                                    Best Under <span>&#8377;</span>30000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?minPrice=30000&maxPrice=40000">
                                    Best Under <span>&#8377;</span>40000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?minPrice=40000&maxPrice=50000">
                                    Best Under <span>&#8377;</span>50000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?minPrice=50000&maxPrice=65000">
                                    Best Under <span>&#8377;</span>65000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?minPrice=65000&maxPrice=75000">
                                    Best Under <span>&#8377;</span>75000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?minPrice=75000&maxPrice=85000">
                                    Best Under <span>&#8377;</span>85000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?minPrice=85000&maxPrice=95000">
                                    Best Under <span>&#8377;</span>95000
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(true);
                            }}
                            onMouseLeave={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Feature{" "}
                              <span className="right-arrow features">
                                {">"}
                              </span>
                            </span>
                            {showFeaturesSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets">5G Tablets</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets">
                                    Android Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?ram=8">
                                    8GB RAM Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?ram=12">
                                    12GB RAM Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?rom=256">
                                    256GB ROM Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?rom=64">
                                    64GB Tablets
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?battery=8000-mah">
                                    8000 mAh Battery
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/smart-tablets?frontCamera=12">
                                    Best Camera Tablets
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="submenuItem"
                      onMouseEnter={() => setShowSubSubMenu(true)}
                      onMouseLeave={() => setShowSubSubMenu(false)}
                    >
                      <MdOutlineLaptopMac size={20} color="#111111" />
                      <Link to="/laptop">Laptops</Link>
                      {showSubSubMenu && (
                        <div className="subsubmenu">
                          <div className="subsubmenuItem">
                            <Link to="/laptop">All Laptops</Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/laptop/?available=bestseller">
                              Popular Laptops
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/laptop/?available=upcoming">
                              Upcoming Laptops
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/laptop/?available=new">New Laptops</Link>
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isBrandHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Popular Brands{" "}
                              <span className="right-arrow popular-brands">
                                {">"}
                              </span>
                            </span>
                            {showPopularBrandsSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?brand=samsung">
                                    Samsung Laptops
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?brand=apple">
                                    Apple Laptops
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?brand=hp">HP Laptops</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?brand=lenovo">
                                    Lenovo Laptops
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?brand=dell">
                                    Dell Laptops
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?brand=msi">
                                    MSI Laptops
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?brand=acer">
                                    Acer Laptops
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?brand=realme">
                                    Realme Laptops
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isPriceHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Prices{" "}
                              <span className="right-arrow prices">{">"}</span>
                            </span>
                            {showPriceSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?minPrice=10000&maxPrice=20000">
                                    Best Under <span>&#8377;</span>20000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?minPrice=20000&maxPrice=30000">
                                    Best Under <span>&#8377;</span>30000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?minPrice=30000&maxPrice=40000">
                                    Best Under <span>&#8377;</span>40000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?minPrice=40000&maxPrice=50000">
                                    Best Under <span>&#8377;</span>50000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?minPrice=50000&maxPrice=60000">
                                    Best Under <span>&#8377;</span>60000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?minPrice=60000&maxPrice=75000">
                                    Best Under <span>&#8377;</span>75000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?minPrice=75000&maxPrice=85000">
                                    Best Under <span>&#8377;</span>85000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?minPrice=85000&maxPrice=95000">
                                    Best Under <span>&#8377;</span>95000
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(true);
                            }}
                            onMouseLeave={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Feature{" "}
                              <span className="right-arrow features">
                                {">"}
                              </span>
                            </span>
                            {showFeaturesSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?ram=8">
                                    8GB RAM Laptops
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?ram=16">
                                    16GB RAM Laptops
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop">Windows 10 Laptops</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop">Windows 11 Laptops</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop?brand=dell">
                                    Dell i5 Laptops
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop">Core i7 Laptops</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop">Core i9 Laptops</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/laptop">Touch Screen Laptops</Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="submenuItem"
                      onMouseEnter={() => setShowSubSubMenu(true)}
                      onMouseLeave={() => setShowSubSubMenu(false)}
                    >
                      <PiTelevisionBold size={20} color="#111111" />
                      <Link to="/tvs">TVs</Link>
                      {showSubSubMenu && (
                        <div className="subsubmenu">
                          <div className="subsubmenuItem">
                            <Link to="/tvs">All Televisions</Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/tvs/?available=bestseller">
                              Popular Televisions
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/tvs/?available=upcoming">
                              Upcoming Televisions
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/tvs/?available=new">
                              New Televisions
                            </Link>
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isBrandHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Popular Brands{" "}
                              <span className="right-arrow popular-brands">
                                {">"}
                              </span>
                            </span>
                            {showPopularBrandsSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?brand=samsung">
                                    Samsung TV
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?brand=sony">Sony TV</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?brand=mi">Mi TV</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?brand=lg">LG TV</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?brand=panasonic">
                                    Panasonic TV
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?brand=thomson">
                                    Thomson TV
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?brand=kodak">Kodak TV</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?brand=acer">Acer TV</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?brand=toshiba">
                                    Toshiba TV
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isPriceHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Prices{" "}
                              <span className="right-arrow prices">{">"}</span>
                            </span>
                            {showPriceSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?minPrice=10000&maxPrice=25000">
                                    Best Under <span>&#8377;</span>25000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?minPrice=20000&maxPrice=35000">
                                    Best Under <span>&#8377;</span>35000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?minPrice=30000&maxPrice=45000">
                                    Best Under <span>&#8377;</span>45000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?minPrice=40000&maxPrice=55000">
                                    Best Under <span>&#8377;</span>55000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?minPrice=50000&maxPrice=65000">
                                    Best Under <span>&#8377;</span>65000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?minPrice=60000&maxPrice=75000">
                                    Best Under <span>&#8377;</span>75000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?minPrice=75000&maxPrice=85000">
                                    Best Under <span>&#8377;</span>85000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs?minPrice=85000&maxPrice=95000">
                                    Best Under <span>&#8377;</span>95000
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(true);
                            }}
                            onMouseLeave={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Feature{" "}
                              <span className="right-arrow features">
                                {">"}
                              </span>
                            </span>
                            {showFeaturesSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">4K TVs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">Smart TVs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">Android TVs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">OLED TVs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">Full HD TVs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">Curved TVs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">3D TVs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">Bluetooth TVs</Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              toggleSizeSubMenu();
                              setIsSizeHovered(true);
                            }}
                            onMouseLeave={() => {
                              toggleSizeSubMenu();
                              setIsSizeHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isSizeHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Screens{" "}
                              <span className="right-arrow features">
                                {">"}
                              </span>
                            </span>
                            {showSizeSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">24 inch Televisions</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">32 inch Televisions</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">40 inch Televisions</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">43 inch Televisions</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">50 inch Televisions</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">55 inch Televisions</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">65 inch Televisions</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/tvs">75 inch Televisions</Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="submenuItem"
                      onMouseEnter={() => setShowSubSubMenu(true)}
                      onMouseLeave={() => setShowSubSubMenu(false)}
                    >
                      <HiMiniComputerDesktop size={20} color="#111111" />
                      <Link to="/pcs">Computers</Link>
                      {showSubSubMenu && (
                        <div className="subsubmenu">
                          <div className="subsubmenuItem">
                            <Link to="/pcs">All Computers</Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/pcs/?available=bestseller">
                              Popular Computers
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/pcs/?available=upcoming">
                              Upcoming Computers
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/pcs/?available=new">New Computers</Link>
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isBrandHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Popular Brands{" "}
                              <span className="right-arrow popular-brands">
                                {">"}
                              </span>
                            </span>
                            {showPopularBrandsSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?brand=asus">
                                    Asus Computers
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?brand=apple">
                                    Apple Computers
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?brand=lenovo">
                                    Lenovo Computers
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?brand=lg">LG Computers</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?brand=iball">
                                    iBall Computers
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?brand=zebronics">
                                    Zebronics Computers
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?brand=dell">
                                    Dell Computers
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?brand=zoonis">
                                    Zoonis Computers
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?brand=intel">
                                    Intel Computers
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isPriceHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Prices{" "}
                              <span className="right-arrow prices">{">"}</span>
                            </span>
                            {showPriceSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?maxPrice=10000">
                                    Best Under <span>&#8377;</span>10000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?minPrice=10000&maxPrice=20000">
                                    Best Under <span>&#8377;</span>20000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?minPrice=20000&maxPrice=30000">
                                    Best Under <span>&#8377;</span>30000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?minPrice=30000&maxPrice=40000">
                                    Best Under <span>&#8377;</span>40000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?minPrice=40000&maxPrice=50000">
                                    Best Under <span>&#8377;</span>50000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?minPrice=50000&maxPrice=60000">
                                    Best Under <span>&#8377;</span>60000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?minPrice=60000&maxPrice=70000">
                                    Best Under <span>&#8377;</span>70000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs?minPrice=70000&maxPrice=80000">
                                    Best Under <span>&#8377;</span>80000
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(true);
                            }}
                            onMouseLeave={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Feature{" "}
                              <span className="right-arrow features">
                                {">"}
                              </span>
                            </span>
                            {showFeaturesSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/pcs">Gaming PCs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs">Core i5 PCs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs">Core i7 PCs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs">Mini PCs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs">All in One PCs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs">8GB RAM PCs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/pcs">16 GB RAM PCs</Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="submenuItem"
                      onMouseEnter={() => setShowSubSubMenu(true)}
                      onMouseLeave={() => setShowSubSubMenu(false)}
                    >
                      <CgSmartHomeRefrigerator size={20} color="#111111" />
                      <Link to="/refrigerator">Refrigerators</Link>
                      {showSubSubMenu && (
                        <div className="subsubmenu">
                          <div className="subsubmenuItem">
                            <Link to="/refrigerator">All Refrigerators</Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/refrigerator/?available=bestseller">
                              Popular Refrigerators
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/refrigerator/?available=upcoming">
                              Upcoming Refrigerators
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/refrigerator/?available=new">
                              New Refrigerators
                            </Link>
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isBrandHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Popular Brands{" "}
                              <span className="right-arrow popular-brands">
                                {">"}
                              </span>
                            </span>
                            {showPopularBrandsSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?brand=samsung">
                                    Samsung Fridges
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?brand=godrej">
                                    Godrej Fridges
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?brand=bosch">
                                    Bosch Fridges
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/televisions?brand=lg">
                                    LG Fridges
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?brand=panasonic">
                                    Panasonic Fridges
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?brand=haier">
                                    Haier Fridges
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?brand=whirlpool">
                                    Whirlpool Fridges
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?brand=voltas">
                                    Voltas Fridges
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?brand=lloyd">
                                    Lloyd Fridges
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isPriceHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Prices{" "}
                              <span className="right-arrow prices">{">"}</span>
                            </span>
                            {showPriceSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?maxPrice=10000">
                                    Best Under <span>&#8377;</span>10000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?minPrice=10000&maxPrice=20000">
                                    Best Under <span>&#8377;</span>20000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?minPrice=20000&maxPrice=30000">
                                    Best Under <span>&#8377;</span>30000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?minPrice=30000&maxPrice=40000">
                                    Best Under <span>&#8377;</span>40000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?minPrice=40000&maxPrice=50000">
                                    Best Under <span>&#8377;</span>50000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?minPrice=50000&maxPrice=65000">
                                    Best Under <span>&#8377;</span>65000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?minPrice=65000&maxPrice=75000">
                                    Best Under <span>&#8377;</span>75000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator?minPrice=75000&maxPrice=85000">
                                    Best Under <span>&#8377;</span>85000
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(true);
                            }}
                            onMouseLeave={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Feature{" "}
                              <span className="right-arrow features">
                                {">"}
                              </span>
                            </span>
                            {showFeaturesSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator">
                                    Single Door Fridge
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator">
                                    Double Door Fridge
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator">
                                    Convertible Fridge
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator">
                                    Side by Side Door
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator">5 Star Fridges</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator">300L Capacity</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator">500L Capacity</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/refrigerator">
                                    French Door Fridges
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="submenuItem"
                      onMouseEnter={() => setShowSubSubMenu(true)}
                      onMouseLeave={() => setShowSubSubMenu(false)}
                    >
                      <TbAirConditioning size={20} color="#111111" />
                      <Link to="/acs">Air Conditioners</Link>
                      {showSubSubMenu && (
                        <div className="subsubmenu">
                          <div className="subsubmenuItem">
                            <Link to="/acs">All ACs</Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/acs/?available=bestseller">
                              Popular ACs
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/acs/?available=upcoming">
                              Upcoming ACs
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/acs/?available=new">New ACs</Link>
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isBrandHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Popular Brands{" "}
                              <span className="right-arrow popular-brands">
                                {">"}
                              </span>
                            </span>
                            {showPopularBrandsSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/acs?brand=blue-star">
                                    Blue Star ACs
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?brand=lg">LG ACs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?brand=voltas">Voltas ACs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?brand=lloyd">Lloyd ACs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?brand=panasonic">
                                    Panasonic ACs
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?brand=carrier">
                                    Carrier ACs
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?brand=daikin">Daikin ACs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?brand=samsung">
                                    Samsung ACs
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?brand=hitachi">
                                    Hitachi ACs
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isPriceHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Prices{" "}
                              <span className="right-arrow prices">{">"}</span>
                            </span>
                            {showPriceSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/acs?maxPrice=20000">
                                    Best Under <span>&#8377;</span>20000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?minPrice=20000&maxPrice=30000">
                                    Best Under <span>&#8377;</span>30000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?minPrice=30000&maxPrice=40000">
                                    Best Under <span>&#8377;</span>40000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?minPrice=40000&maxPrice=50000">
                                    Best Under <span>&#8377;</span>50000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?minPrice=50000&maxPrice=60000">
                                    Best Under <span>&#8377;</span>60000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?minPrice=60000&maxPrice=70000">
                                    Best Under <span>&#8377;</span>70000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?minPrice=70000&maxPrice=80000">
                                    Best Under <span>&#8377;</span>80000
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs?minPrice=80000&maxPrice=95000">
                                    Best Under <span>&#8377;</span>95000
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(true);
                            }}
                            onMouseLeave={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Shop by Feature{" "}
                              <span className="right-arrow features">
                                {">"}
                              </span>
                            </span>
                            {showFeaturesSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/acs">Window ACs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs">Split ACs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs">Inverter ACs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs">Portable ACs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs">3 Star ACs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs">5 Star ACs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs">1 Ton ACs</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/acs">2 Ton ACs</Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="submenuItem"
                      onMouseEnter={() => setShowSubSubMenu(true)}
                      onMouseLeave={() => setShowSubSubMenu(false)}
                    >
                      <TbVacuumCleaner size={20} color="#111111" />
                      <Link to="/microwave">Appliances</Link>
                      {showSubSubMenu && (
                        <div className="subsubmenu">
                          <div className="subsubmenuItem">
                            <Link to="/microwave">All Appliances</Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/microwave/?available=bestseller">
                              Popular Appliances
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/microwave?available=upcoming">
                              Upcoming Appliances
                            </Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/microwave/?available=new">
                              New Appliances
                            </Link>
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePopularBrandsSubMenu();
                              setIsBrandHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isBrandHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Home Appliance{" "}
                              <span className="right-arrow popular-brands">
                                {">"}
                              </span>
                            </span>
                            {showPopularBrandsSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/washmac">Washing Machines</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/geyser">Geysers</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/iron">Irons</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/airpurifier">Air Purifiers</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/waterpurifier">
                                    Water Purifiers
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/aircooler">Air Coolers</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/vacuumcleaner">
                                    Vacuum Cleaners
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(true);
                            }}
                            onMouseLeave={() => {
                              togglePriceSubMenu();
                              setIsPriceHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isPriceHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Kitchen Applian.{" "}
                              <span className="right-arrow prices">{">"}</span>
                            </span>
                            {showPriceSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/microwave">Microwave Ovens</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/sandwichmaker">
                                    Sandwich Makers
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/induction">
                                    Induction Cooktops
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/chimney">Chimneys</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/electrickettle">
                                    Electric Kettles
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/handblender">Hand Blenders</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/mixerjuicer">
                                    Mixer Juicer Grinders
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="subsubmenuItem"
                            onMouseEnter={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(true);
                            }}
                            onMouseLeave={() => {
                              toggleFeaturesSubMenu();
                              setIsHovered(false);
                            }}
                          >
                            <span
                              style={{
                                color: isHovered ? "#1C61E7" : "#111111",
                              }}
                            >
                              Personal Applian.{" "}
                              <span className="right-arrow features">
                                {">"}
                              </span>
                            </span>
                            {showFeaturesSubMenu && (
                              <div
                                className="submenu"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "100%",
                                }}
                              >
                                <div className="subsubmenuItem">
                                  <Link to="/trimmer">Trimmers</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/haircurler">Hair Curlers</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/hairdryer">Hair Dryers</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/hairstraightner">
                                    Hair Straighteners
                                  </Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/shaver">Shavers</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/epilator">Epilators</Link>
                                </div>
                                <div className="subsubmenuItem">
                                  <Link to="/toothbrush">Toothbrushes</Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="submenuItem"
                      onMouseEnter={() => setShowSubSubMenu(true)}
                      onMouseLeave={() => setShowSubSubMenu(false)}
                    >
                      <BsEarbuds size={18} color="#111111" />
                      <Link to="/earbud">Audio & Wearables</Link>
                      {showSubSubMenu && (
                        <div className="subsubmenu">
                          <div className="subsubmenuItem">
                            <Link to="/smartwatch">Smart Watches</Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/fitnessband">Fitness Bands</Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/earphone">Earphones</Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/earbud">Earbuds</Link>
                          </div>
                          <div className="subsubmenuItem">
                            <Link to="/headphone">Headphones</Link>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="submenuItem">
                      <TbBrand4Chan size={20} color="#111111" />
                      <Link to="/more">Top Brands</Link>
                    </div>
                  </div>
                )}
              </div>
              <div className="navbarBottomText">
                <Link to="/">Home</Link>
                <Link to="/about">About Us</Link>
                <Link to="/contact">Contact Us</Link>
                <div>Shop</div>
                <Link to="/blogs">Blogs</Link>
                <div className="navbarDropdown z-10">
                  <Link>News & Reviews</Link>
                  <div className="navbarDropdownContent z-10">
                    <Link to="/news">News</Link>
                    <Link to="/reviews">Reviews</Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="compareWrapper"
              onMouseEnter={() => handleCompareSubMenuToggle(true)}
              onMouseLeave={() => handleCompareSubMenuToggle(false)}
            >
              <div className="compareBtn">
                <FaGgCircle size={25} color="#1C61E7" />
                <span>Compare</span>
              </div>
              {/* Naveb */}
              <div
                className={`compareSubMenu ${showCompareSubMenu ? "show" : ""}`}
              >
                <Link to="/mobiles/comparison">Mobiles</Link>
                <Link to="/tablets/comparison">Tablets</Link>
                <Link to="/laptops/comparison">Laptops</Link>
                <Link to="/televisions/comparison">TVs</Link>
                <Link to="/pcs/comparison">Computers</Link>
                <Link to="/air_conditioners/comparison">ACs</Link>
                <Link to="/refrigerators/comparison">Fridges</Link>
                <Link to="/washing_machines/comparison">Washing</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
